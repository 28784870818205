import React, { useState } from 'react'
import { Link } from 'react-router-dom';

const SearchInput = props => {
    const { value = null, didYouMean = null } = props;
    const [searchQ, setSearchQ] = useState(value);

    return (
        <div className='md:w-full flex flex-col items-start'>
            <form action="/tutorials" className="flex w-full">
                <input name="q" value={searchQ} onChange={e => setSearchQ(e.target.value)} type="text" id="" className="rounded-none rounded-l-lg border placeholder:text-persianGreen border-persianGreen border-r-0 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm px-2 py-1" placeholder="search tutorials" />
                <button type="submit" className="cursor-pointer flex justify-center items-center px-2 bg-transparent rounded-r-md border border-r-1 border-persianGreen text-persianGreen h-8">
                    <svg className='w-6 h-6' width="20" height="15" viewBox='0 0 20 15' fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m12.6638 9.68848 5.993 3.63502" stroke="#04B8A7" strokeWidth="3" strokeMiterlimit="22.9256"/><path d="M10.2956 12.1151c2.8207-1.548 3.8525-5.08962 2.3045-7.91038S7.51047.35219 4.68971 1.9002C1.86895 3.44821.83719 6.9898 2.3852 9.81056c1.54801 2.82074 5.0896 3.85254 7.9104 2.30454Z" stroke="#04B8A7" strokeWidth="2" strokeMiterlimit="22.9256"/></svg>
                </button>
            </form>
            {
                didYouMean !== null && didYouMean.length > 0 && <Link to={{ pathname: '/tutorials', search: `q=${didYouMean.query}`}} reloadDocument className='w-full underline text-left mt-2'>Did you mean <span className='italic'>{didYouMean.query}</span>?</Link>
            }
        </div>
    )
}

export default SearchInput;