import React from "react";
import { Link } from "react-router-dom";

const TutorialTypes = () => {

    const types = [
        {
          name: '2.5"',
          sub: 'Squares',
          query: {
            'Fabric Cut': 'Mini Charms - 2.5" squares'
          }
        },
        {
          name: '5"',
          sub: 'Squares',
          query: {
            'Fabric Cut': 'Charm Pack - 5" Squares'
          }
        },
        {
          name: '10"',
          sub: 'Squares',
          query: {
            'Fabric Cut': 'Layer Cake - 10" Squares'
          }
        },
        {
          name: '2.5"',
          sub: 'Strips',
          query: {
            'Fabric Cut': 'Jelly Roll - 2.5" Strips'
          }
        },
        {
          name: 'Fat',
          sub: 'Quarters',
          query: {
            'Fabric Cut': 'Fat Quarters'
          }
        },
        {
          name: 'Hex',
          sub: '',
          query: {
            'Fabric Cut': 'Hexagon'
          }
        }
      ]

    return(
        <section id="types">
            <div className="container mx-auto my-12 lg:max-w-5xl">
                <div className="mx-auto px-4 lg:px-0 lg:max-w-4xl">
                    <h2 className="text-2xl text-center md:text-left text-bittersweet montserrat mb-12">Types &gt;</h2>
                    <div className={`grid grid-cols-2 md:grid-cols-${types.length} gap-8`}>
                        {
                            types.map(( el, i) => (
                              <Link to={{ pathname:'/tutorials', search: `filter.fabric_cut=${el.query["Fabric Cut"]}` }} key={i} className={`p-4 ${ i <= 4 ? 'bg-persianGreen' : 'bg-greenPawPaw'} text-white`}>
                                  <div className="flex flex-col items-center justify-center">
                                      <div className="text-2xl lg:text-4xl mb-4">{ el.name }</div>
                                      <div className="uppercase font-extrabold"> { el.sub }</div>
                                  </div>
                              </Link>
                            ))
                        }
                    </div>                    
                </div>
            </div>
        </section>
    )
}

export default TutorialTypes;