import React, { useContext, useEffect, useState } from "react";
import { useParams, Link } from 'react-router-dom'
import API from '../utils/API';
import { find } from 'lodash';
import { Helmet } from 'react-helmet';
import { RecentContext } from "../context/RecentContext";

import AdNetwork from "../components/widgets/AdNetwork";

import Header from '../components/layout/Header';
import Hero from '../components/widgets/Hero';
import TutorialDetail from '../components/layout/TutorialDetail';
import TutorialProducts from '../components/layout/TutorialProducts';
// import TutorialBoxes from "../layout/TutorialBoxes";
import TutorialsList from '../components/layout/TutorialsList';
import Footer from '../components/layout/Footer';
import Loader from "../components/widgets/Loader";
import VideoTranscript from "../components/layout/VideoTranscript";
import Loader404 from '../components/widgets/404';

const Tutorial = () => {
    const [tutorialData, setTutorialData] = useState([]);
    const [tutorialMeta, setTutorialMeta] = useState([]);
    const [isFetching, setIsFetching] = useState(true);
    const [errorFetching, setErrorFetching] = useState(false);
    const recentTutorials = useContext(RecentContext);

    const [showVideo, setShowVideo] = useState(false);

    const metaInfo = {
        title: 'Quilting Tutorials',
        meta: [
            {
                name: 'description',
                content: 'The best free quilting tutorials on the web! Watch fun and engaging videos from anywhere, on your desktop or mobile device.'
            }
        ]
    }

    const { slug } = useParams();
    
    useEffect(() => {
        //const tutorial_storage = JSON.parse(localStorage.getItem(`${slug}`));
        setErrorFetching(false)
        // if(tutorial_storage) {
        //     setTutorialData(tutorial_storage.data?.data[0]);
        //     setTutorialMeta(tutorial_storage.data?.meta);
        // } else {
            API.get(`/tutorials`, {params: {slug: slug}})
            .then( res => {
                if(res.data.data.length) {
                    //localStorage.setItem(`${slug}`, JSON.stringify(res));
                    setTutorialData(res.data.data[0]);
                    setTutorialMeta(res.data.meta);
                } else {
                    setErrorFetching(true);
                    // const redirectTimer = setTimeout(() => {
                    //     window.location.replace('/');
                    // }, 8000)
                    // return () => clearTimeoue(redirectTimer);
                }
            })
            .catch(err => {
                setErrorFetching(true);
            });
        //}
        setIsFetching(false);
    }, [slug])

    const difficulty = () => {
        const category = find(tutorialData.categories, x => x.category_type.id === 7);
            if(typeof category !== 'undefined') return category.title;
    }

    const heroImage = () => {
        const img = find(tutorialData.images, x => x.type === 'hero');
        const youtube_maxres = find(tutorialData.images, x => x.type === 'youtube_maxres');

        if (typeof img === 'undefined') {
            return youtube_maxres.url
        }
  
        return img.url
    }

    const transcript = () => {
        const snippet = find(tutorialData.snippets, x => x.type === 'transcript');
        if(typeof snippet !== 'undefined') return snippet.content.replace(/\n/gi, '<br>');
        return ''
    }

    return(
        <>
            <Helmet>
                {
                    tutorialData && tutorialData.title
                    ? <title>{ tutorialData.title } - { metaInfo.title }</title>
                    : <title>{ metaInfo.title }</title>
                }
                
                {
                    metaInfo.meta.map((el, i) => <meta name={el.name} content={el.content} key={i} /> )
                }
            </Helmet>
            <Header />

            {
                isFetching && (
                    <Loader />
                )
            }

            {
                !isFetching && errorFetching && (
                    <Loader404 />
                )
            }

            {
                !isFetching && !errorFetching && tutorialData && tutorialData.title && (
                    <>
                        <Hero title={tutorialData.title} 
                            tutorial={true} 
                            youtube_id={tutorialData.video.youtube_id} 
                            difficulty={difficulty()} 
                            duration={tutorialData.video.duration} 
                            heroImage={heroImage()}
                            showVideo={showVideo}
                            toggleVideo={setShowVideo} />
                        
                        <TutorialDetail details={tutorialData} meta={tutorialMeta} showVideo={showVideo} toggleVideo={setShowVideo} />
                        
                        {/* <AdNetwork handle="daily-deal" /> */}

                        {
                            tutorialData.products && tutorialData.products.length > 0 && (
                                <TutorialProducts products={tutorialData.products} />
                            )
                        }

                        {
                            transcript().length > 0 && (
                                <VideoTranscript snippet={transcript()} />
                            )
                        }

                        {/*
                        <div className="container montserrat mx-auto my-12 lg:max-w-5xl p-8">
                            <div className="mx-auto lg:max-w-4xl">
                                <div className="text-2xl font-bold">reviews here</div>
                            </div>
                        </div>

                        <TutorialBoxes />
                        */}

                        {
                            recentTutorials?.length > 0 && (
                                <TutorialsList title="Recent tutorials" tutorials={ recentTutorials } recent={true} feature={0} source="API" />
                            )
                        }
                    </>
                )
            }

            <Footer />
        </>
    )
}

export default Tutorial;