import React from "react";

const AboutQT = () => {
    return(
        <section id="about">
            <div className="container px-4 md:px-0 mx-auto my-12 lg:max-w-5xl">
                <div className="mx-auto md:px-4 lg:px-0 lg:max-w-4xl">
                    <div className="w-full my-8 md:my-0">
                        <div className="">
                            <div className="text-bittersweet text-2xl">About</div>
                            <div className="text-bittersweet text-2xl font-extrabold mb-4">QuiltingTutorials.com</div>

                            <div className="relative float-right mb-8 md:ml-12 md:-mt-12">
                                <img src="/img/about-border.svg" alt="" className="absolute w-auto h-auto md:-mt-3 -ml-2" />
                                <img src="/img/about-qt.jpg" alt="" className="w-auto h-auto mt-12" />
                            </div>

                            <p className="text-black my-2 leading-6">In 2009, Jenny Doan entered the world of YouTube quilting, releasing her very first free quilt tutorial. Since then, she's released hundreds more Missouri Star Quilt Company tutorials on YouTube, becoming the most popular quilting instructors in the world.</p>
                            <p className="text-black my-2 leading-6">Even though Missouri Star has become well known for top-notch quilting video tutorials, so many other instructors also produce quality quilt tutorials online. This is why we created Quilting Tutorials - a site that brings all the best YouTube quilting videos to one place!</p>
                            <p className="text-black my-2 leading-6">Quilting Tutorials allows you to sort through hundreds of free quilting videos so that you can find the perfect project. Sort by skill level, materials used, project type and more to find the right quilt tutorial for you.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutQT;
