import React from "react";
import { Link } from 'react-router-dom';
import { find } from 'lodash';

import SupplyList from "./SupplyList";
import MissingImg4 from '../../assets/img/launcher-icon-4x.png';
import TutorialDetailMobile from "./TutorialDetailMobile";

const TutorialDetail = (props) => {
    const { title, quilt_size_length, quilt_size_width, categories, pdfs, links, supplies, snippets } = props.details;

    const tutorialSnippet = snip => {
        return snip.find(i => i.type === 'about').content;
    }

    const instructor = () => {
        const category = find(categories, x => x.category_type.id === 2);
        return typeof category !== 'undefined' ? category.title : false
    }

    const instructorAvatar = () => {
        const category = find(categories, x => x.category_type.id === 2);
        
        if (category !== undefined) {
            const instructorImages = category.images;
            return typeof instructorImages !== 'undefined' ? instructorImages[0].url : MissingImg4;
        }
        return MissingImg4;
    }

    const instructorDesc = () => {
        const category = find(categories, x => x.category_type.id === 2);

        if(category !== undefined) {
            const instructorBio = category;
            return typeof instructorBio !== 'undefined' ? instructorBio.description : false
        }        
    }
    
    return (
        <section>
            <div className="container mx-auto md:mt-12 relative lg:max-w-5xl">
                <div className="mx-auto lg:max-w-4xl">
                    <TutorialDetailMobile {...props.details} showVideo={props.showVideo} toggleVideo={props.toggleVideo} />
                    {/* <div className="flex justify-around gap-8"> */}
                    <div className="hidden md:grid md:grid-cols-3 gap-12 auto-cols-fr">
                        {/* <div className="w-2/3"> */}
                        <div className="col-span-2">
                            <div>
                                <h2 className="text-2xl font-bold text-bittersweet">About the { title }</h2>
                                <div className="crumbs text-persianGreen text-sm truncate mt-4">
                                    <span className="font-bold"><Link to="/" className='hover:underline'>home</Link> &gt; <Link to="/tutorials" className="hover:underline">tutorials</Link> &gt;</span> <span className="">{ title.split('(')[0].slice(0,48) }</span>
                                </div>
                                <img src="/img/tutorial-title-header.svg" alt="" className='-ml-8' />
                            </div>

                            <div className="my-8">
                                {
                                    quilt_size_width > 0 && quilt_size_length > 0 && (
                                        <h3 className="text-xl font-bold">Quilt Size: { quilt_size_width }" x { quilt_size_length }"</h3>
                                    )
                                }

                                { 
                                    snippets && (
                                        <p className={`my-8`}>
                                            { tutorialSnippet(snippets) }
                                        </p>
                                    )
                                }

                                <h3 className="text-xl my-8 font-bold">Tutorial Resources</h3>
                                <ul className="list-disc list-inside">
                                    {
                                        links && links.length > 0 && (
                                            <li><a href={ links[0].url } target="_blank" rel='noreferrer' className="text-bittersweet underline hover:no-underline">{ links[0].title }</a></li>
                                        )
                                    }
                                    {
                                        pdfs && pdfs.length > 0 && (
                                            <li>Layout PDF <a href={ pdfs[0].url } target="_blank" rel='noreferrer' download className="text-bittersweet underline">(Download PDF)</a></li>
                                        )
                                    }
                                    
                                </ul>
                            </div>

                            <div className="bg-lightCyan p-8">
                                <div className="flex">
                                    <div className="flex flex-col items-center justify-center flex-none">
                                        <img src={ instructorAvatar() } onError={ (e) => e.target.src = MissingImg4 } alt="" className="w-36 h-36 rounded-full border-4 border-bittersweet" />
                                    </div>
                                    <div className="ml-4">
                                        {/* About */} Instructor
                                        <div className="font-bold text-lg">{ instructor() }</div>
                                        <div className="mt-2 mb-4">
                                            {
                                                instructorDesc() && (
                                                    <div className="">{ instructorDesc() }</div>
                                                )
                                            }
                                        </div>
                                        {/* 
                                        <div className="links flex flex-row items-center">
                                            <div className="flex">
                                                <img src="/img/icon-youtube.svg" alt="" className="mr-2" />
                                                <Link to="/" className='text-sm font-bold hover:underline'>youtube</Link>
                                            </div>
                                            <div className="ml-8 flex">
                                                <img src="/img/icon-website.svg" alt="" className="mr-2" />
                                                <Link to="/" className='text-sm font-bold hover:underline'>website</Link>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <img src='/img/tutorial-about-bottom.png' alt='' />
                        </div>
                        {/* <div className="w-1/3"> */}
                        <div className="">
                            <SupplyList list={supplies} styles="w-auto h-full inline-block my-4" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TutorialDetail;