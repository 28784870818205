import Home from '../views/Home';
import Search from '../views/Search';
import Tutorial from '../views/Tutorial';
import Landing from '../views/Landing';
import Error404 from '../views/404';

const CategoryRoutes = [
    { name: 'Home',      path: '/',                  element: <Home /> },
    { name: 'Tutorials', path: '/tutorials',         element: <Search /> },
    { name: 'Tutorial',  path: '/tutorial/:slug',    element: <Tutorial /> },
    { name: 'Filtered',  path: ':filter/:value',     element: <Search /> },
    { name: 'Landing',   path: '/landing',           element: <Landing /> },
    { name: 'Quilter Cotton Fabric', path: '/Quilters-Cotton-Fabric', element: <Search {...{filter: 'fabric_material', value: 'Quilter"s+Cotton'}} /> },
    { name: 'Paper Piecing Quilt Tutorials', path: '/Paper-Piecing-Quilt-Tutorials', element: <Search {...{filter: 'technique', value: 'Paper+Piecing'}} /> },
    { name: 'Hand Pieced Quilt Tutorials', path: '/Hand-Pieced-Quilt-Tutorials', element: <Search {...{filter: 'technique', value: 'Hand+Pieced'}} /> },
    { name: 'Art Quilting Tutorials', path: '/Art-Quilting-Tutorials', element: <Search {...{filter: 'technique', value: 'Art+Quilting'}}/> },
    { name: 'Applique Quilt Tutorials', path: '/Applique-Quilt-Tutorials', element: <Search {...{filter: 'technique', value: 'Applique'}}/> },
    { name: 'Quilts Set on Point Tutorials', path: '/Quilts-Set-on-Point-Tutorials', element: <Search {...{filter: 'technique', value: 'Quilts+Set+on+Point'}}/> },
    { name: 'Template Quilting Tutorials', path: '/Template-Quilting-Tutorials', element: <Search {...{filter: 'technique', value: 'Template+Quilting'}}/> },
    { name: 'Quilt as You Go Tutorials', path: '/Quilt-as-You-Go-Tutorials', element: <Search {...{filter: 'technique', value: 'Quilt+as+You+Go'}}/> },
    { name: 'English Paper Piecing Quilt Tutorials', path: '/English-Paper-Piecing-Quilt-Tutorials', element: <Search {...{filter: 'technique', value: 'English+Paper+Piecing'}}/> },
    { name: 'In the Hoop / Machine Embroidery Quilt Tutorials', path: '/In-the-Hoop-Machine-Embroidery-Quilt-Tutorials', element: <Search {...{filter: 'technique', value: 'In+the+Hoop+%2F+Machine+Embroidery'}} /> },
    { name: 'Foundation Paper Piecing Quilt Tutorials', path: '/Foundation-Paper-Piecing-Quilt-Tutorials', element: <Search {...{filter: 'technique', value: 'Foundation+Paper+Piecing'}} /> },
    { name: '60 Degree Triangles Quilt Blocks', path: '/60-Degree-Triangles-Quilt-Blocks', element: <Search {...{filter: 'block_type', value: '60+Degree+Triangles'}} /> },
    { name: 'Disappearing Quilt Blocks', path: '/Disappearing-Quilt-Blocks', element: <Search {...{filter: 'block_type', value: 'Disappearing+Blocks'}} /> },
    { name: 'Snowball Quilt Blocks', path: '/Snowball-Quilt-Blocks', element: <Search {...{filter: 'block_type', value: 'Snowball'}} /> },
    { name: 'Hourglass Quilt Blocks', path: '/Hourglass-Quilt-Blocks', element: <Search {...{filter: 'block_type', value: 'Hourglass'}} /> },
    { name: 'Pinwheel Quilt Blocks', path: '/Pinwheel-Quilt-Blocks', element: <Search {...{filter: 'block_type', value: 'Pinwheel'}} /> },
    { name: 'Nine Patch Quilt Blocks', path: '/Nine-Patch-Quilt-Blocks', element: <Search {...{filter: 'block_type', value: 'Nine+Patch'}} /> },
    { name: 'Half Square Triangle Quilt Blocks', path: '/Half-Square-Triangle-Quilt-Blocks', element: <Search {...{filter: 'block_type', value: 'Half+Square+Triangle'}} /> },
    { name: 'Four Patch Quilt Blocks', path: '/Four-Patch-Quilt-Blocks', element: <Search {...{filter: 'block_type', value: 'Four+Patch'}} /> },
    { name: 'Jacob\'s Ladder Quilt Blocks', path: '/Jacobs-Ladder-Quilt-Blocks', element: <Search {...{filter: 'block_type', value: 'Jacob"s+Ladder'}} /> },
    { name: 'Dresden Quilt Blocks', path: '/Dresden-Quilt-Blocks', element: <Search {...{filter: 'block_type', value: 'Dresden'}} /> },
    { name: 'Wall Hangings', path: '/Wall-Hangings', element: <Search {...{filter: 'project_type', value: 'Wall+Hanging'}} /> },
    { name: 'Quilted Table Runners', path: '/Quilted-Table-Runners', element: <Search {...{filter: 'project_type', value: 'Table+Runner'}} /> },
    { name: 'Baby Quilts', path: '/Baby-Quilts', element: <Search {...{filter: 'project_type', value: 'Baby'}} /> },
    { name: 'Lap / Throw Quilts', path: '/Lap-Throw-Quilts', element: <Search {...{filter: 'project_type', value: 'Lap+/+Throw'}} /> },
    { name: 'Twin Quilts', path: '/Twin-Quilts', element: <Search {...{filter: 'project_type', value: 'Twin'}} /> },
    { name: 'Double Quilts', path: '/Double-Quilts', element: <Search {...{filter: 'project_type', value: 'Double'}} /> },
    { name: 'Queen Quilts', path: '/Queen-Quilts', element: <Search {...{filter: 'project_type', value: 'Queen'}} /> },
    { name: 'King Quilts', path: '/King-Quilts', element: <Search {...{filter: 'project_type', value: 'King'}} /> },
    { name: 'Quilted Apparels', path: '/Quilted-Apparels', element: <Search {...{filter: 'project_type', value: 'Quilted+Apparel'}} /> },
    { name: 'Pillows & Cushions', path: '/Pillows-Cushions', element: <Search {...{filter: 'project_type', value: 'Pillows+&+Cushions'}} /> },
    { name: 'Quilted Totes, Bag, Pursess', path: '/Quilted-Totes-Bag-Pursess', element: <Search {...{filter: 'project_type', value: 'Totes%2C+Bag%2C+Purses'}} /> },
    { name: 'Chenille', path: '/Chenille', element: <Search {...{filter: 'quilting_skills', value: 'Chenille'}} /> },
    { name: 'Couching', path: '/Couching', element: <Search {...{filter: 'quilting_skills', value: 'Couching'}} /> },
    { name: 'Fabric Dying', path: '/Fabric-Dying', element: <Search {...{filter: 'quilting_skills', value: ''}} /> },
    { name: 'Fabric Painting', path: '/Fabric-Painting', element: <Search {...{filter: 'quilting_skills', value: ''}} /> },
    { name: 'Embellishing', path: '/Embellishing', element: <Search {...{filter: 'quilting_skills', value: 'Embellishing'}} /> },
    { name: 'Long Arm Quilting', path: '/Long-Arm-Quilting', element: <Search {...{filter: 'quilting_skills', value: 'Long+Arm+Quilting'}} /> },
    { name: 'Free Motion Quilting', path: '/Free-Motion-Quilting', element: <Search {...{filter: 'quilting_skills', value: 'Free+Motion+Quilting'}} /> },
    { name: 'Quilting Organization', path: '/Quilting-Organization', element: <Search {...{filter: 'quilting_skills', value: 'Quilting+Organization'}} /> },
    { name: 'Tools & Notions How-To', path: '/Tools-Notions-How-To', element: <Search {...{filter: 'quilting_skills', value: 'Tools+%26+Notions+How-To'}} /> },
    { name: 'Basic Quilting Skills', path: '/Basic-Quilting-Skills', element: <Search {...{filter: 'quilting_skills', value: 'Basic+Quilting+Skills'}} /> },
    { name: 'Quilt Tutorials', path: '/Modern-Quilt-Tutorials', element: <Search {...{filter: 'theme', value: 'Modern'}} />},
    { name: 'Holiday Quilt Tutorials', path: '/Holiday-Quilt-Tutorials', element: <Search {...{filter: 'theme', value: 'Holiday'}} />},
    { name: 'Batik Quilt Tutorials', path: '/Batik-Quilt-Tutorials', element: <Search {...{filter: 'theme', value: 'Batik'}} />},
    { name: 'Civil War Quilt Tutorials', path: '/Civil-War-Quilt-Tutorials', element: <Search {...{filter: 'theme', value: 'Civil+War'}} />},
    { name: '1930\'s Quilt Tutorials', path: '/1930s-Quilt-Tutorials', element: <Search {...{filter: 'theme', value: '1930s'}} />},
    { name: 'Memory Quilt Tutorials', path: '/Memory-Quilt-Tutorials', element: <Search {...{filter: 'theme', value: 'Memory+Quilt'}} />},
    { name: 'Heirloom Quilt Tutorials', path: '/Heirloom-Quilt-Tutorials', element: <Search {...{filter: 'theme', value: 'Heirloom'}} />},
    { name: 'Traditional Quilt Tutorials', path: '/Traditional-Quilt-Tutorials', element: <Search {...{filter: 'theme', value: 'Traditional'}} />},
    { name: 'Primitive Quilt Tutorials', path: '/Primitive-Quilt-Tutorials', element: <Search {...{filter: 'theme', value: 'Primitive'}} />},
    { name: 'Wedding Quilt Tutorials', path: '/Wedding-Quilt-Tutorials', element: <Search {...{filter: 'theme', value: 'Wedding'}} />},
    { name: 'Solids Quilt Tutorials', path: '/Solids-Quilt-Tutorials', element: <Search {...{filter: 'theme', value: 'Solids'}} />},
    { name: 'T-Shirt Quilt Tutorials', path: '/T-Shirt-Quilt-Tutorials', element: <Search {...{filter: 'theme', value: 'T-Shirt+Quilt'}} />},
    { name: 'Graduation Quilt Tutorials', path: '/Graduation-Quilt-Tutorials', element: <Search {...{filter: 'theme', value: ''}} />},
    { name: 'College Quilt Tutorials', path: '/College-Quilt-Tutorials', element: <Search {...{filter: 'theme', value: ''}} />},
    { name: 'Home Dec Fabrics', path: '/Home-Dec-Fabrics', element: <Search {...{filter: 'fabric_material', value: 'Home+Dec'}} />},
    { name: 'Denim Fabrics', path: '/Denim-Fabrics', element: <Search {...{filter: 'fabric_material', value: 'Denim'}} />},
    { name: 'Knit Fabrics', path: '/Knit-Fabrics', element: <Search {...{filter: 'fabric_material', value: 'Knit'}} />},
    { name: 'Wool Fabrics', path: '/Wool-Fabrics', element: <Search {...{filter: 'fabric_material', value: 'Wool'}} />},
    { name: 'Linen Fabrics', path: '/Linen-Fabrics', element: <Search {...{filter: 'fabric_material', value: 'Linen'}} />},
    { name: 'Flannel Fabrics', path: '/Flannel-Fabrics', element: <Search {...{filter: 'fabric_material', value: 'Flanel'}} />},
    { name: 'Fleece Fabrics', path: '/Fleece-Fabrics', element: <Search {...{filter: 'fabric_material', value: 'Fleece'}} />},
    { name: 'Cuddle/Minky Fabrics', path: '/Cuddle-Minky-Fabrics', element: <Search {...{filter: 'fabric_material', value: 'Cuddle%2FMinky'}} />},
    { name: 'Batik Cotton Fabrics', path: '/Batik-Cotton-Fabrics', element: <Search {...{filter: 'fabric_material', value: 'Batik+Cotton'}} />},
    { name: 'Quilter\'s Cotton Fabrics', path: '/Quilters-Cotton-Fabrics', element: <Search {...{filter: 'fabric_material', value: 'Quilter"s+Cotton'}} />},
    { name: 'Mini Charms - 2.5" squares' , path: '/Mini-Charms-2.5-inch-squares', element: <Search {...{filter: 'fabric_cut', value: 'Mini+Charms+-+2.5"+squares'}} />},
    { name: 'Charm Pack - 5" Squares' , path: '/Charm-Pack-5-inch-Squares', element: <Search {...{filter: 'fabric_cut', value: 'Charm+Pack+-+5"+Squares'}} />},
    { name: 'Layer Cake - 10" Squares' , path: '/Layer-Cake-10-inch-Squares', element: <Search {...{filter: 'fabric_cut', value: 'Layer+Cake+-+10"+Squares'}} />},
    { name: 'Jelly Roll - 2.5" Strips' , path: '/Jelly-Roll-2.5-inch-Strips', element: <Search {...{filter: 'fabric_cut', value: 'Jelly+Roll+-+2.5"+Strips'}} />},
    { name: 'Turnover - 6" Triangles' , path: '/Turnover-6-inch-Triangles', element: <Search {...{filter: 'fabric_cut', value: 'Turnover+-+6"+Triangles'}} />},
    { name: 'Fat Quarters' , path: '/Fat-Quarters', element: <Search {...{filter: 'fabric_cut', value: 'Fat+Quarters'}} />},
    { name: 'Scraps' , path: '/Scraps', element: <Search {...{filter: 'fabric_cut', value: 'Scraps'}} />},
    { name: 'Panels' , path: '/Panels', element: <Search {...{filter: 'fabric_cut', value: 'Panels'}} />},
    { name: 'Fabric by Yard Only' , path: '/Fabric-by-Yard-Only', element: <Search {...{filter: 'fabric_cut', value: 'Fabric+by+Yard+Only'}} />},
    { name: 'Honey Buns - 1.5" Strips' , path: '/Honey-Buns-1.5-inch-Strips', element: <Search {...{filter: 'fabric_cut', value: 'Honey+Buns+-+1.5"+Strips'}} />},
    { name: '', path: '*', element: <Error404 />},
];

export default CategoryRoutes;