import React from 'react';

const TutorialInfoWidget = props => {

    const { label, value, icon, dir } = props;

    const textDir = () => {
        if (dir === 'left') {
            return {text: 'text-left', margin: 'ml-2'}
        } else if (dir === 'right'){
            return {text: 'text-right', margin: 'mr-2'}
        }
    }

    return (
        <div className='my-4 md:mt-12 flex items-center'>
            <div className={`${textDir().text} text-persianGreen md:text-white font-bold montserrat ${textDir().margin} ${dir === 'left' ? 'order-2' : 'order-1'}`}>
                <div className='uppercase text-sm leading-3 md:text-base md:drop-shadow'>{ label }</div>
                <div className='text-lg leading-4 md:text-2xl md:drop-shadow'>{ value }</div>
            </div>
            <img src={ icon } alt="" className={`h-9 w-9 md:h-auto md:w-auto ${dir === 'left' ? 'order-1' : 'order-2'}`} />
        </div>
    )
}

export default TutorialInfoWidget;