import React from "react";

const SupplyListItem = (props) => {
    const { item } = props;
    
    return (
        <div className="text-center group">
            <a href={item.url} target="_blank" rel='noreferrer'>
                <div className="flex flex-row justify-center items-center gap-1">
                    <div className="flex flex-col justify-center items-center">
                        <div className="bg-persianGreen group-hover:bg-somethingGreen md:bg-somethingGreen group-hover:bg-bittersweet rounded-full w-14 h-14 text-sm font-bold flex flex-col justify-center items-center">
                            <div className="leading-none">{ item.quantity }</div>
                            <div className="leading-none">{ item.unit }</div>
                        </div>
                    </div>
                    <svg className="flex-none w-6 h-6" viewBox="0 0 28 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M1.44605 2.62066c1.004 0 1.89703.781 2.07203 1.785C5.41408 18.9066 5.41401 18.9066 5.41401 18.9066c.048.558.55802.94 1.11602.94H24.377c.557 0 1.004-.382 1.115-.94l1.673-12.26993c.048-.335-.064-.62205-.287-.89305-.271-.222-.557-.33398-.892-.33398H5.86003c-.175-1.291-.175-1.29101-.175-1.29101-.271-2.119-2.05598-3.728982-4.23898-3.728982-.604996 0-1.114995.494015-1.114995 1.116012 0 .605.509999 1.115 1.114995 1.115ZM24.711 7.64065 23.309 17.6796H7.47002C6.19503 7.64063 6.19503 7.64065 6.19503 7.64065H24.711ZM10.927 28.8336c0 .622-.494 1.116-1.11495 1.116-.621 0-1.115-.494-1.115-1.116 0-.621.494-1.115 1.115-1.115.62095 0 1.11495.494 1.11495 1.115Zm11.155 0c0 .622-.494 1.116-1.115 1.116-.622 0-1.116-.494-1.116-1.116 0-.621.494-1.115 1.116-1.115.621 0 1.115.494 1.115 1.115Zm-14.50096-7.25c-.605 0-1.115.494-1.115 1.115 0 1.339.89202 3.347 3.34601 3.347H22.64c.621 0 1.115-.51 1.115-1.116 0-.621-.494-1.115-1.115-1.115H9.81205c-.892 0-1.115-.622-1.115-1.116 0-.621-.49401-1.115-1.11601-1.115Zm5.01996-7.808h3.234l-.78 1.004c-.383.494-.335 1.163.175 1.562.223.159.446.223.669.223.335 0 .669-.176.893-.447l2.23-2.788c.335-.399.335-1.0039 0-1.4029-2.23-2.78804-2.23-2.78809-2.23-2.78809-.399-.49399-1.068-.55799-1.562-.16-.51.383-.558 1.05199-.175 1.56199l.78 1.004h-3.234c-.606 0-1.116.494-1.116 1.115 0 .606.51 1.116 1.116 1.116Z" fill="#fff"/></svg>
                </div>
                <div className="text-left text-sm font-bold group-hover:underline">
                    { 
                        item.display_title ? item.display_title : item.title
                    }
                </div>
            </a>
        </div>
    )
}

export default SupplyListItem;