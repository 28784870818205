import React from 'react';
import { Link } from 'react-router-dom';
import SearchInput from './SeachInput';
import TutorialStat from './TutorialStat';

const Hero = props => {
    const { title, message = false, tutorial = false, difficulty = false, youtube_id, heroImage, duration = false, showVideo, toggleVideo } = props

    const heroTitle = () => {
        if (title) {
            return (<div className='px-6'>{ title.split('(')[0] }</div>)
        }else{
            return (<div className="text-center"><div>Only Quilt Tutorials.</div><div>Only for Quilters.</div></div>)
        }
    }

    const video_src = () => {
        return `https://www.youtube.com/embed/${youtube_id}?rel=0&showinfo=0&vq=hd720&autoplay=1&color=white&iv_load_policy=3`
      }
    
    return (
        <section>
            <div className="container mx-auto md:mt-8 relative lg:max-w-5xl">
                <div className="mx-auto grid grid-cols-3 md:gap-8 lg:max-w-4xl" style={{height: '27rem'}}>
                    <div className={`hidden md:flex flex-col justify-between items-start`}>
                        <div className={`z-10 relative`}>
                            <div className={`rounded-t-lg w-full py-2 ${tutorial ? 'bg-persianGreen' : 'bg-bittersweet'}`}>
                                <div className="text-white text-lg font-bold mt-1">
                                    {
                                        showVideo 
                                            ? <div className="text-center cursor-pointer flex justify-center" onClick={() => toggleVideo(!showVideo)}><div className="font-bold pointer-events-none mr-2">x</div> close video</div>
                                            : heroTitle(title)
                                    }
                                </div>
                            </div>
                            {
                                tutorial ? <img src='/img/tutorial-hero-border.png' alt="" className='w-full h-auto -mt-1' /> 
                                         : <img src='/img/hero-banner-bg.png' alt="" className='w-full h-auto -mt-1' />
                            }
                        </div>
                        {
                            message && (
                                <p className="bottom-0 py-10 px-10 bg-gray-50 bg-opacity-90 text-sm">
                                    { message }
                                </p>
                            )
                        }
                    </div>
                    
                    <div className="hidden md:flex flex-col items-end">
                        <SearchInput />
                        { 
                            tutorial && difficulty && difficulty.length > 0 && !showVideo && (
                                <div className="hidden md:inline-block">
                                    <TutorialStat label="difficulty" value={ difficulty } icon="/img/icon_difficulty.svg" dir="right" />
                                </div>
                            )
                        }
                    </div>

                    <div className="w-full hidden md:flex flex-col items-end">
                        <Link to="/tutorials" className="bg-bittersweet text-white text-center rounded font-bold px-4 py-1 w-full h-8">Browse all tutorials</Link>
                        {
                            tutorial && duration && duration.length > 0 && !showVideo && (
                                <div className="hidden md:inline-block">
                                    <TutorialStat label="duration" value={ duration } icon="/img/icon_time.svg" dir="right" />
                                </div>
                            )
                        }
                    </div>
                </div>
                
                {
                    showVideo 
                        ? <div className="video-player absolute bottom-0 w-full h-96 bg-center bg-no-repeat bg-cover z-20" ><iframe className='h-96 w-full' src={video_src()} title="tutorial_video" frameBorder="0" allowFullScreen></iframe></div>
                        : <div className="absolute bottom-0 w-full h-96 bg-center bg-no-repeat bg-cover z-0" style={{ zIndex:'-10', backgroundImage: `linear-gradient(180deg, #1A1A1A -15.2%, rgba(26, 26, 26, 0) 41.29%), url(${heroImage})`}}></div>
                }
                
                {
                    tutorial && (
                        <img src="/img/video-play-button.svg" title="Play video" alt="Play video icon" className={`inset-0 mx-auto mt-48 w-24 h-24 cursor-pointer ${ showVideo ? 'hidden' : 'absolute'}`} onClick={() => toggleVideo(!showVideo)} />
                    )
                }
            </div>
            {
                !tutorial && (
                    <div className='md:hidden bg-persianGreen p-4 py-12'>
                        <div className={`-mt-24 md:px-4`}>
                            <div className={`rounded-t-lg w-full py-2 ${tutorial ? 'bg-persianGreen' : 'bg-bittersweet'}`}>
                                <div className="text-white text-lg font-bold mt-1">
                                    { heroTitle(title) }
                                </div>
                            </div>
                            {
                                tutorial ? <img src='/img/tutorial-hero-border.png' alt="" className='w-full h-auto -mt-1' /> 
                                        : <img src='/img/hero-banner-bg.png' alt="" className='w-full h-auto -mt-1' />
                            }
                            
                        </div>
                        <div className='text-center mt-8 px-8'>
                            { message }
                        </div>
                    </div>
                )
            }
        </section>
    )
}

export default Hero;