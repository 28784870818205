import React from 'react'
import { Link } from 'react-router-dom';

const Loader404 = () => {
    return (
        <div className='container mx-auto flex flex-col justify-center items-center my-32'>
            <img src="/img/404.svg" alt="" className='w-1/2 h-auto' />
            <div className='text-semibold text-xl'>We couln't find the page your we're looking for.</div>
            <Link to="/tutorials" className="bg-bittersweet text-white text-center rounded-lg text-lg font-bold mt-1 px-4 py-2 w-1/4">Browse all tutorials</Link>
        </div>
    )
}

export default Loader404;