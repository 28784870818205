import React, { useState } from 'react'
import parse from 'html-react-parser';

import { useFontSize } from '../../hooks/useFontSize';

const VideoTranscript = (props) => {
    const [showMore, setShowMore] = useState(false);
    const { FontSizeUI, fontSize } = useFontSize();
    const { snippet } = props;

    return (
        <section>
            <div className="container mx-auto mt-8 md:mt-12 px-4 md:px-auto relative lg:max-w-5xl">
                <div className="mx-auto lg:max-w-4xl">
                    <div className='flex flex-row justify-between'>
                        <div className="text-2xl font-bold text-persianGreen">Video Transcript</div>
                        { FontSizeUI }
                    </div>
                    <div className={`mt-6`}>
                        <div className={`${fontSize.string} ${ showMore ? 'h-auto overflow-y-hidden' : 'h-64 overflow-hidden' }`}>
                            { parse(snippet) }
                        </div>
                        <div className="border-b border-gray-300 flex justify-center mt-6">
                            <div onClick={() => setShowMore(!showMore)} className="border border-gray-200 py-2 px-6 rounded -mb-5 text-white text-base font-bold bg-bittersweet cursor-pointer hover:bg-persianGreen hover:shadow">
                                { !showMore ? <>Read More</> : <>Read Less</> }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default VideoTranscript;