import React from "react";
import { sortBy } from "lodash";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import InstructorSlide from "../widgets/InstructorSlide";

const Instructors = props => {
    let { instructors } = props

    // sort by how many results for facet
    instructors = sortBy(instructors, x => -x.counter);

    function PrevArrow(props) {
        const { onClick } = props;
        return (
            <img src="/img/arrow-left.svg" alt="" className="hidden md:block w-8 h-32 hover:cursor-pointer" onClick={ onClick } />
        );
    }
    
    function NextArrow(props) {
        const { onClick } = props;
        return (
            <img src="/img/arrow-right.svg" alt="" className="hidden md:block w-8 h-32 hover:cursor-pointer" onClick={ onClick } />
        );
    }

    let settings = {
        accessibility: true,
        arrows: true,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
        slidesToShow: 5,
        responsive: [
            { breakpoint: 896, settings: { slidesToShow: 4, slidesToScroll: 3 } },
            { breakpoint: 640, settings: { slidesToShow: 3, slidesToScroll: 3 } },
            { breakpoint: 480, settings: { slidesToShow: 2, slidesToScroll: 2, infinite: true } }            
        ]
    }

    return(
        <section id="instructors">
            <div className="container px-4 mx-auto my-16 lg:max-w-5xl">
                <div className="mx-auto lg:max-w-4xl">
                    
                    <div className="text-center text-2xl font-bold uppercase text-greenPawPaw mb-8">View by instructors</div>
                    {/* <a href="/" className="block text-center text-bittersweet underline mb-4">show all</a> */}
                    
                    <Slider {...settings}>
                        {
                            instructors && instructors.map((el, i) => (
                                <InstructorSlide info={el} key={i} />
                            ))
                        }
                    </Slider>
                    
                </div>
            </div>
        </section>
    )
}

export default Instructors;