import React from 'react'

import Header from '../components/layout/Header';

const Landing = () => {
    return (
        <>
            <Header />        
        </>
    )
}

export default Landing;