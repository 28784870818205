import React from 'react';

const SearchPagination = props => {
    const { currentPage, totalPages, handleFilters } = props;

    return (
        <div className='mx-4 md:mx-0 flex justify-between mt-8'>
            {
                currentPage !== 1  
                    ? <div className="text-white text-center text-sm font-bold rounded-lg py-2 px-4 bg-persianGreen md:w-32 cursor-pointer" onClick={(e) => handleFilters('page', parseInt(currentPage - 1))}>&lt; <span className='hidden md:inline-block'>previous</span></div> 
                    : <div className='w-12 md:w-32'></div>
            }
            
            <div className='text-center text-greenPawPaw bg-lightCyan text-sm font-bold rounded-lg py-2 px-4 w-32'>{ currentPage } of { totalPages }</div>

            {
                currentPage !== totalPages
                    ? <div className="text-white text-center text-sm font-bold rounded-lg py-2 px-4 bg-persianGreen md:w-32 cursor-pointer" onClick={(e) => handleFilters('page', parseInt(currentPage + 1))}><span className='hidden md:inline-block'>next</span> &gt;</div>
                    : <div className='w-12 md:w-32'></div>
            }
            
        </div>
    )
}

export default SearchPagination;