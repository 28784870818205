import React, { useContext } from "react";
import { Link } from 'react-router-dom';
import { sortBy, filter } from "lodash";
import { MenuContext } from "../../context/MenuContext";

import MenuRoutes from '../../utils/MenuRouteObj';

const Menu = () => {
    const items = useContext(MenuContext);
    const filter_order = ['fabric_cut','skill_level','theme','block_type','fabric_material'];

    const linker = (el,opt) => {
        if (MenuRoutes[opt.label]) return MenuRoutes[opt.label]
        return { pathname: '/tutorials', search: `filter.${el}=${opt.value}`}
    }

    return (
        <>
            {
                items && items.length > 0 && (
                    <section>
                        <div className="hidden md:block container mx-auto lg:mt-4 lg:max-w-4xl">
                            <nav className="flex justify-between">
                                {
                                    filter_order.map((el, i) => (
                                        <div className="group relative dropdown px-4 cursor-pointer tracking-wide" key={i}>
                                            <div className="text-lg font-bold capitalize">{ el.replace('_',' ') } <img src="/img/menu-arrow.svg" alt="" className="inline-block ml-1" /></div>
                                            <div className="group-hover:flex shadow w-52 bg-white dropdown-menu absolute z-50 hidden h-auto">
                                                <ul className="w-full">
                                                    {
                                                        filter(items).find(x => x.field === el) && (
                                                            <>
                                                                {
                                                                    sortBy(filter(items).find(x => x.field === el).values, c => -c.count).map((opt,b) => (
                                                                        <li className="py-1 px-4 hover:bg-gray-100 whitespace-nowrap" key={`${b}_${i}`}>
                                                                            <Link to={ linker(el, opt) } reloadDocument className="block">
                                                                                {
                                                                                    el === 'fabric_cut' ? (
                                                                                        <>
                                                                                            {
                                                                                                opt.label.includes('Only')
                                                                                                ? opt.label.split('Only')[0]
                                                                                                : opt.label.split('-')[0]
                                                                                            }
                                                                                        </>
                                                                                    ) : (
                                                                                        <>{ opt.label }</>
                                                                                    )
                                                                                }
                                                                            </Link>
                                                                        </li>
                                                                    ))
                                                                }
                                                            </>
                                                        )
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    ))
                                }
                                </nav>
                            </div>
                    </section>
                )
            }
        </>
    )
}

export default Menu;