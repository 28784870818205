const MenuRoutes = {
    'Layer Cake - 10" Squares': '/Layer-Cake-10-inch-Squares',
    'Jelly Roll - 2.5" Strips': '/Jelly-Roll-2.5-inch-Strips',
    'Fabric by Yard Only': '/Fabric-by-Yard-Only',
    'Charm Pack - 5" Squares': '/Charm-Pack-5-inch-Squares',
    'Scraps': '/Scraps',
    'Fat Quarters' : '/Fat-Quarters',
    'Mini Charms - 2.5" squares': '/Mini-Charms-2.5-inch-squares',
    'Panels': '/Panels',
    'Honey Buns - 1.5" Strips': '/Honey-Buns-1.5-inch-Strips',
    'Turnover - 6" Triangles': '/Turnover-6-inch-Triangles',
    'Traditional': '/Traditional-Quilt-Tutorials',
    'Modern': '/Modern-Quilt-Tutorials',
    'Holiday': '/Holiday-Quilt-Tutorials',
    'Batik': '/Batik-Quilt-Tutorials',
    'Solids': '/Solids-Quilt-Tutorials',
    'Primitive': '/Primitive-Quilt-Tutorials',
    '1930s': '/1930s-Quilt-Tutorials',
    'Civil War': '/Civil-War-Quilt-Tutorials',
    'Heirloom': '/Heirloom-Quilt-Tutorials',
    'Memory Quilt': '/Memory-Quilt-Tutorials',
    'T-Shirt Quilt': '/T-Shirt-Quilt-Tutorials',
    'Wedding': '/Wedding-Quilt-Tutorials',        
    'Graduation': '/Graduation-Quilt-Tutorials',
    'Half Square Triangle': '/Half-Square-Triangle-Quilt-Blocks',
    'Four Patch': '/Four-Patch-Quilt-Blocks',
    'Nine Patch': '/Nine-Patch-Quilt-Blocks',
    'Snowball': '/Snowball-Quilt-Blocks',
    'Pinwheel': '/Pinwheel-Quilt-Blocks',
    'Hourglass': '/Hourglass-Quilt-Blocks',
    'Dresden': '/Dresden-Quilt-Blocks',
    'Disappearing Blocks': '/Disappearing-Quilt-Blocks',
    '60 Degree Triangles': '/60-Degree-Triangles-Quilt-Blocks',
    'Jacob"s Ladder': '/Jacobs-Ladder-Quilt-Blocks',
    'Quilter"s Cotton': '/Quilters-Cotton-Fabric',
    'Batik Cotton': '/Batik-Cotton-Fabrics',
    'Home Dec': '/Home-Dec-Fabrics',
    'Cuddle/Minky': '/Cuddle-Minky-Fabrics',
    'Flannel': '/Flannel-Fabrics',
    'Denim': '/Denim-Fabrics',
    'Linen': '/Linen-Fabrics',
    'Wool': '/Wool-Fabrics',
    'Fleece': '/Fleece-Fabrics',
    'Knit': '/Knit-Fabrics',
}

export default MenuRoutes;