import React from "react";
import { filter, sortBy } from "lodash";
import InstructorsFilter from "../widgets/InstructorsFilter";

const Filters = props => {
    const { searchFilters, filters, handleFilters, removeFilter } = props;
    // const [filterOptions, setFilterOptions] = useState();
    const filter_order = ['fabric_cut','skill_level','theme','block_type','fabric_material','project_type','quilting_skills','technique'];

    const instructors = filters.length ? sortBy(filter(filters).find(x => x.label === 'instructors').values, x => -x.count) : null;
    
    // useEffect(() => {
    //     const omit = ['Sort By', 'instructors']//, 'block_type', 'fabric_material'
    //     setFilterOptions(filter(filters, x => omit.indexOf(x.field) === -1))
    // }, [filters])

    const updateFilters = (c, el) => {
        handleFilters(Array(c), el);
    }
    
    const cleanFilters = () => {
        return Object.keys(searchFilters).filter(i => i !== 'per_page' && i !== 'q' && i !== 'page' && i !== 'index' && !i.includes('sort'))
    }

    const filterDisplay = (p, f, key) => {
        if(!Array.isArray(f)) {
            return (
                <div className="flex items-center group cursor-pointer px-4 md:px-2" onClick={() => removeFilter(p, f)} key={key}>
                    <div className="font-bold mr-1 pointer-events-none">x</div>
                    <div className="mr-2 pointer-events-none">{ f.split('-')[0] }</div>
                </div>
            )
        }else{
            return (
                f.map((el, j) => (
                    <div className="flex items-center group cursor-pointer px-4 py-1 md:px-2 md:py-.5 text-lg md:text-base" key={key} onClick={() => removeFilter(p, el)}>
                        <div className="font-bold mr-1 pointer-events-none">x</div>
                        <div className="mr-2 pointer-events-none">{ el.split('-')[0] }</div>
                    </div>
                ))
            )
        }
    }

    return (
        <>
            { searchFilters['q'] && 
                <div className="border-b border-persianGreen mb-6 py-8 md:py-0">
                    <div className="hidden md:block text-xl text-bittersweet uppercase font-bold pb-1 mb-4">Search Term</div>
                    <div className="flex justify-center md:justify-start items-center mb-4 cursor-pointer" onClick={(e) => removeFilter('q', searchFilters['q'])}>
                        <div className="font-bold pointer-events-none">x</div>
                        <div className="ml-2 pointer-events-none">{ searchFilters['q'] }</div>
                    </div>
                </div>
            }

            {
                Object.entries(searchFilters).length > 0 && cleanFilters() && cleanFilters().length > 0 && (
                    <div className="border-b border-persianGreen mb-6 py-8 md:py-0">
                        <div className="hidden md:block text-xl text-bittersweet uppercase font-bold pb-1 mb-4">Filter</div>
                        <div className="flex flex-wrap justify-center md:justify-start items-center md:mb-4">
                            {
                                cleanFilters().map((k,i) => (
                                    filterDisplay(k, searchFilters[k], i)
                                ))
                            }
                        </div>
                    </div>
                )
            }

            {/* {
                instructors && (
                    <InstructorsFilter list={instructors} displayCount={12} updateFilters={updateFilters} />
                )
            } */}

            {
                filters && filters.length > 0 && filter_order.map((el,k) => (
                    <div className="border-b border-persianGreen mb-6" key={k}>
                        <div className="text-center md:text-left text-xl text-bittersweet uppercase font-bold pb-1 mb-4">{el.replace('_', ' ')}</div>
                        {
                            el === 'fabric_cut' && filter(filters).find(x => x.field === el) && (
                                <div className="flex flex-wrap justify-start mb-4">
                                    {
                                        filter(filters).find(x => x.field === el).values.map((opt,b) => (
                                            <div key={b} className="w-1/2 first:h-18 flex flex-col justify-between items-center mb-8 cursor-pointer" onClick={() => updateFilters(`filter.${el}`, opt.value)}>
                                                <div className="flex justify-center items-center h-full">
                                                    <img data-name={opt.value.toLowerCase().split('-')[0].trim().replaceAll(' ','-')} src={`/img/filters/cut-${opt.value.toLowerCase().split('-')[0].trim().replaceAll(' ','-')}.svg`} alt="" className="w-20 md:w-10 h-auto" onError={ (e) => e.target.src = '/img/filters/cut-mini-charms.svg' } />
                                                </div>
                                                <div className="text-center">
                                                    {
                                                        opt.value.includes('Only')
                                                        ? <div className="font-bold text-sm">{opt.value.split('Only')[0]}</div>
                                                        : <><div className="font-bold text-sm">{opt.value.split('-')[0]}</div><div className="text-xs">{opt.value.split('-')[1]}</div></>
                                                    }
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            )
                        }
                        {
                            el !== 'fabric_cut' && filter(filters).find((x,k) => x.field === el) && (
                                <div className="mb-4">
                                    <ul>
                                        {
                                            filter(filters).find(x => x.field === el).values.map((opt,b) => (    
                                                el !== 'fabric_cut' && (
                                                    <li key={b} className="cursor-pointer text-center md:text-left text-xl md:text-base py-1 md:py-0" onClick={() => updateFilters(`filter.${el}`, opt.value)}>{ opt.value.replace('_', ' ') }</li>
                                                )
                                            ))
                                        }
                                    </ul>
                                </div>
                            )
                        }
                    </div>
                ))
            }
        </>
    )
}

export default Filters;