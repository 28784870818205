import React from "react";
import { Link } from "react-router-dom";
import linksConfig from '../../utils/linksConfig';

const Footer = () => {
    const year = () => {
        return new Date().getFullYear();
    }

    return(
        <footer className="bg-lightCyan px-4 md:px-0 mx-auto mt-12 lg:max-w-5xl">
            <div className="">
                <div className="container mx-auto lg:max-w-4xl py-24 px-4 lg:px-0">
                    <div className="flex flex-col md:flex-row justify-between items-center">
                        <div>
                            <Link to="/"><img src="/img/logo.svg" alt="" className="h-16 w-auto" /></Link>
                            <div className="mt-1 text-gray-500 text-xs text-center">&copy; 2011-{ year() } Missouri Star Quilt Co.</div>
                        </div>
                        
                        <div className="flex flex-row my-8 md:my-0">
                            {
                                linksConfig.social.map((el, k) => (
                                    el.location && (
                                        <a href={ el.location } target="_blank" key={k} rel="noreferrer"><img src={ el.logo } alt="" className="w-8 h-8 mx-1" /></a>
                                    )
                                ))
                            }
                        </div>

                        <div className="flex flex-row">
                            <a href="https://itunes.apple.com/us/app/quilting-tutorials-by-missouri-star-quilt-company/id513781546?mt=8" target="_blank" rel='noreferrer'><img src="https://linkmaker.itunes.apple.com/assets/shared/badges/en-us/appstore-lrg.svg" alt="" className="h-10 w-auto" /></a>
                            <a href="https://play.google.com/store/apps/details?id=com.missouriquiltco.quiltingtutorialsapp" target="_blank" rel='noreferrer'><img src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png" alt="" className="h-14 -mt-2 w-auto" /></a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;