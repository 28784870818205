import React from 'react';

const Loader = () => {
    const randomMessage = [
        'Gathering tutorials... Quak!',
        'Where\'s Jenny?',
        'May the tutorials be with you.',
        'Quak! I love those new vids.',
        'Instructor Rob... what a dream boat.',
        '3233 + 89285 = QUAK!',
        "I'm a chicken, NOT a duck. QUAK!",
        "Quilter's Daily Deal. More like... I gotta have it!"
    ]

    return (
        <div className='container mx-auto flex flex-col justify-center items-center my-64'>
            <img src="/img/quilton.svg" alt="" className='w-32 h-32 mb-8' />
            <div>{ randomMessage[Math.floor(Math.random() * randomMessage.length)] }</div>
        </div>
    )
}

export default Loader;