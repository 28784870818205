import React, { useState } from 'react';

const InstructorsFilter = props => {
    const { list, displayCount, updateFilters } = props;
    const [showInstructors, setShowInstructors] = useState(false);

    const instructorIcon = (el, k) => {
        return <div onClick={() => updateFilters('filter.instructors', el.value)} key={k} className={`h-16 w-16 border-2 border-white rounded-full cursor-pointer`}>{el.value}</div>
    }

    return (
        <div className="border-b border-persianGreen mb-6">
            <div className="text-xl text-bittersweet uppercase font-bold pb-1 mb-4">Instructors</div>
            <div className={`grid grid-cols-3 gap-2 ${list.length <= 12 ? 'mb-6' : 'mb-2'}`}>
                {
                    list.slice(0, displayCount).map((el, k) => (
                        instructorIcon(el, k)
                    ))
                }
            </div>
            {
                list.length > 12 && (
                    <>
                        <div id="fade" className={`grid grid-cols-3 gap-2 mb-4 transition-all ${showInstructors ? '' : 'h-0 invisible opacity-0'}`}>
                            {
                                list.slice(13, (list.length-displayCount)).map((el, k) => (
                                    instructorIcon(el, k)
                                ))
                            }
                        </div>
                        <button className='mb-6 text-bittersweet hover:font-semibold' onClick={() => setShowInstructors(!showInstructors)}>Show <span className='action'>{showInstructors ? 'less' : 'more'}</span></button>
                    </>
                )
            }
        </div>
    )
} 

export default InstructorsFilter;