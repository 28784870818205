const msqc = 'https://www.missouriquiltco.com'

export default {
  msqc,
  quickLinks: [
    {location: `${msqc}?utm_campaign=qthome&utm_medium=microsite&utm_source=quiltingtut`, title: 'Quilt Shop', icon: '/img/header/msqc.svg', textColor: 'softOrange'},
    {location: `${msqc}/pages/daily-deal?utm_campaign=qthome&utm_medium=microsite&utm_source=quiltingtut`, title: `Daily Deal`, icon: '/img/header/daily.svg', textColor: 'persianGreen'},
    {location: `${msqc}/collections/on-sale?utm_campaign=qthome&utm_medium=microsite&utm_source=quiltingtut`, title: `Sales / Trending`, icon: '/img/header/sale.svg', textColor: 'vividYellow'}
  ],
  sisterSites: [
    {location: msqc, title: 'Quilt Shop'},
    {location: `${msqc}/pages/daily-deal`, title: `Quilter's Daily Deal`},
    {location: 'http://quiltsby.me', title: 'Show & Tell for Quilters'},
    {location: 'http://forum.missouriquiltco.com', title: 'Forum'},
    {location: 'http://blog.missouriquiltco.com', title: 'Blog'}
  ],
  social: [
    {location: '', title: 'Pinterest', logo: '/img/social/pinterest.svg'},
    {location: 'https://www.facebook.com/quiltingtutorials', title: 'Facebook', logo: '/img/social/facebook.svg'},
    {location: 'https://www.instagram.com/quilttutorials/', title: 'Instagram', logo: '/img/social/instagram.svg'},
    {location: '', title: 'Youtube', logo: '/img/social/youtube.svg'}
  ]
}