import React from "react";

const OutboundLinks = () => {
    return (
        <div className="px-4 md:px-0 mx-auto mt-32 lg:max-w-5xl">
            <div className="container mx-auto md:px-4 lg:px-0 lg:max-w-4xl">
                <div className="my-16 flex flex-col md:flex-row items-center md:justify-between gap-8 mx-auto lg:max-w-4xl">
                    <a href="https://missouriquiltco.com/" target="_blank" rel="noreferrer"><img src="/img/logos/msqc-logo.svg" alt="" className="" /></a>
                    <a href="https://www.youtube.com/user/MissouriQuiltCo" target="_blank" rel="noreferrer"><img src="/img/logos/youtube-subscribe.svg" alt="" className="" /></a>
                    <a href="http://quiltsby.me/" target="_blank" rel="noreferrer"><img src="/img/logos/quitls-by-me.svg" alt="" className="" /></a>
                </div>
            </div>
        </div>
    )
}

export default OutboundLinks;