import { data } from 'autoprefixer';
import React from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

import MissingImg4 from '../../assets/img/launcher-icon-4x.png';
import { getMedium, getSmall } from '../../utils/ImageResource';


const VideoFeature = props => {
    const { feature_tut, display_button = true, recent = false, justify = 'between' } = props;
    const { title } = props.data;

    const video_hero = (data) => {
        if(typeof data.thumbnail_image === 'string' && data.thumbnail_image.length > 0){
            return data.thumbnail_image;
        }else if(typeof data.hero_image === 'string' && data.hero_image.length > 0) {
            return data.hero_image;
        }else if(typeof data.youtube_maxres === 'string' && data.youtube_maxres.length > 0) {
            return data.youtube_maxres;
        }else if(typeof data.hero_image === 'undefined' && typeof data.images === 'object') {
            const hero_image = data.images.find(i => i.type = 'hero');
            if(hero_image.url){
                return hero_image.url;
            }
        }
    }

    const tutorialTitle = () => {
        return props.data.title ? parse(title.split('(')[0]) : parse(props.data.name.split('(')[0]);
    }

    const tutorialSlug = () => {
        return props.data.slug ? `/tutorial/${props.data.slug}` : props.data.url.split('.com')[1]
    }

    const showMeTheButton = () => {
        if (recent) {
            return 'hidden md:inline-block bg-persianGreen hover:bg-bittersweet hover:text-white text-center w-full rounded-lg text-xs font-bold py-2';
        }else{
            return 'hidden md:inline-block bg-lightCyan hover:bg-greenPawPaw hover:text-white text-center w-full text-xs font-bold py-2';
        }
    }

    return(
        <>
            {
                feature_tut ? (
                    <Link to={tutorialSlug()} className="col-span-2 group">
                        <img src={ getMedium(video_hero(props.data)) } alt={ tutorialTitle() } className="w-full h-auto aspect-video" />
                        <p className="text-sm mt-4 group-hover:underline group-hover:cursor-pointer">{ tutorialTitle() }</p>
                    </Link>
                ) : (
                    <div className={`flex flex-col justify-${justify} group`}>
                        <Link to={tutorialSlug()} className="">
                            <img src={ getSmall(video_hero(props.data)) } alt="" className="w-full h-auto md:h-20 object-cover object-center" onError={ (e) => e.target.src = MissingImg4 } />
                            <p className="my-4 text-sm text-clip text-left break-words tracking-tight group-hover:underline">{ tutorialTitle() }</p>
                        </Link>
                        {
                            display_button && (
                                <Link to={tutorialSlug()} className={showMeTheButton()}>Watch Now</Link>
                            )
                        }
                    </div>
                )
            }
        </>
    )
}

export default VideoFeature;