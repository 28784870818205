import React from 'react';
import { Link } from 'react-router-dom';

const TutorialListHeader = props => {
    const { title, link } = props;

    const frills = (title) =>{
        if (title.includes("Jenny")){
            return (<img src="/img/thread-needle.svg" alt="" className="block w-16 h-auto absolute right-0 top-0 mt-5 md:-mr-9" style={{ zIndex: '-10' }} />)
        }else if(title.includes("Newest")){
            return (<img src="/img/spool.svg" alt="" className="block w-auto h-12 md:h-16 absolute left-0 top-0 -mt-2 ml-4 md:mt-2 md:-ml-10" style={{zIndex: '-10'}} />)
        }
    }

    const header = (title) => {
        if (title.includes('Recent')) {
            return (
                <>
                    <div className="flex flex-row justify-center md:justify-between items-center">
                        <div className="md:text-center text-3xl text-persianGreen montserrat font-bold">{ title }</div>
                        <img src="/img/machine.svg" alt="" className="hidden md:block w-28 h-auto" />
                    </div>
                    <div className="hidden md:block w-11/12 border-b border-black"></div>
                </>
            )
        }

        return (
            <div className="mx-auto grid grid-cols-1 md:grid-cols-3 md:gap-12 relative px-4">
                { frills(title) }
                <>
                    <h2 className={`${title.includes('Newest') ? 'text-right' : ''} md:text-center text-2xl text-bittersweet montserrat font-bold hover:underline`}>
                        <Link to={link}>{ title }</Link>
                    </h2>
                    {
                        title.includes('Newest') && (
                            <Link to="/tutorials" className="md:hidden text-right text-greenPawPaw underline font-bold">View All Tutorials</Link>
                        )
                    }
                </>
                <div className='hidden md:block'>&nbsp;</div>
                {
                    title.includes('Newest') && (
                        <Link to="/tutorials" className="hidden md:block h-8 py-1 px-8 bg-greenPawPaw text-white text-center rounded">View All Tutorials</Link>
                    )
                }
            </div>
        )
    }

    return (
        <div className="container mx-auto mt-8 lg:max-w-4xl">
            { header(title) }
        </div>
    )
}

export default TutorialListHeader;