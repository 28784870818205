import React from 'react';
import { Link } from 'react-router-dom';
import { find } from 'lodash';

import SupplyList from '../layout/SupplyList';
import TutorialStat from '../widgets/TutorialStat';
import MissingImg4 from '../../assets/img/launcher-icon-4x.png';

const TutorialDetailMobile = props => {
    const { title, quilt_size_width, quilt_size_length, links, pdfs, snippets, supplies, categories, video, showVideo, toggleVideo } = props;

    const tutorialSnippet = snip => {
        return snip.find(i => i.type === 'about').content;
    }

    const instructor = () => {
        const category = find(categories, x => x.category_type.id === 2)
        return typeof category !== 'undefined' ? category.title : false
    }

    const instructorAvatar = () => {
        const category = find(categories, x => x.category_type.id === 2);
        
        if (category !== undefined) {
            const instructorImages = category.images;
            return typeof instructorImages !== 'undefined' ? instructorImages[0].url : MissingImg4;
        }
        return MissingImg4;
    }

    const instructorDesc = () => {
        const category = find(categories, x => x.category_type.id === 2);

        if(category !== undefined) {
            const instructorBio = category;
            return typeof instructorBio !== 'undefined' ? instructorBio.description : false
        }        
    }

    const difficulty = () => {
        const category = find(categories, x => x.category_type.id === 7);
        if(typeof category !== 'undefined') return category.title;
    }

    return (
        <div className='md:hidden'>
            <div className='bg-lightCyan py-8 px-6'>

                <div className={`flex justify-center ${ showVideo ? '-mt-12' : '-mt-24' }`}>
                    <div className={`relative ${ showVideo ? 'z-10' : 'z-20' }`}>
                        <div className='rounded-t-lg py-2 bg-persianGreen px-6 pt-4 pb-12'>
                            <div className="text-white text-xl font-bold mt-1">
                                <div className='text-center px-6'>
                                    { showVideo 
                                        ? <div className="text-center cursor-pointer flex justify-center" onClick={() => toggleVideo(!showVideo)}><div className="font-bold pointer-events-none mr-2">x</div> close video</div>
                                        : title 
                                    }
                                </div>
                            </div>
                        </div>
                        <img src='/img/tutorial-hero-border.png' alt="" className='w-full h-auto -mt-1' />
                    </div>
                </div>

                <div className="px-6 mb-8 flex justify-between">
                    <TutorialStat label="difficulty" value={ difficulty() } icon="/img/icon_difficulty.svg" dir="left" />
                    <TutorialStat label="duration" value={ video.duration } icon="/img/icon_time.svg" dir="left" />
                </div>

                <div className='text-xl font-bold text-bittersweet border-b border-black'>About the {title}</div>
                <div className="crumbs text-persianGreen text-sm truncate mt-4">
                    <span className="font-bold"><Link to="/" className='hover:underline'>home</Link> &gt; <Link to="/tutorials" className="hover:underline">tutorials</Link> &gt;</span> <span className="">{ title.split('(')[0].slice(0,48) }</span>
                </div>
                <div className="my-8">
                    {
                        quilt_size_width > 0 && quilt_size_length > 0 && (
                            <h3 className="text-xl font-bold">Quilt Size: { quilt_size_width }" x { quilt_size_length }"</h3>
                        )
                    }
                    { 
                        snippets && snippets.length && (
                            <p className="my-8 text-lg">{ tutorialSnippet(snippets) }</p>
                        )
                    }
                    <h3 className="text-xl my-8 font-bold">Tutorial Resources</h3>
                    <ul className="list-disc list-inside">
                        {
                            links.length > 0 && (
                                <li><a href={ links[0].url } target="_blank" rel='noreferrer' className="text-bittersweet underline hover:no-underline">{ links[0].title }</a></li>
                            )
                        }
                        {
                            pdfs.length > 0 && (
                                <li>Layout PDF <a href={ pdfs[0].url } target="_blank" rel='noreferrer' download className="text-bittersweet underline">(Download PDF)</a></li>
                            )
                        }
                        
                    </ul>
                </div>
            </div>
            <div className='bg-persianGreen py-8 px-6'>
                <div className="flex items-center">
                    <div className="border-4 border-bittersweet rounded-full w-28 h-28 flex justify-center items-center">
                        <img src={ instructorAvatar() } onError={ (e) => e.target.src = MissingImg4 } alt="" className="w-30 h-30 rounded-full" />
                    </div>
                    <div className='ml-8'>
                        <div className='text-white'>About Instructor</div>
                        <div className="font-bold text-2xl">{ instructor() }</div>
                    </div>
                </div>
                <div className="my-4 text-veryDarkGray">
                    {
                        instructorDesc() && (
                            <div className="">{ instructorDesc() }</div>
                        )
                    }
                </div>
                {/*
                <div className="links flex flex-row items-center text-white">
                    <div className="flex">
                        <img src="/img/icon-youtube.svg" alt="" className="mr-2" />
                        <Link to="/" className='text-sm font-bold hover:underline'>youtube</Link>
                    </div>
                    <div className="ml-8 flex">
                        <img src="/img/icon-website.svg" alt="" className="mr-2" />
                        <Link to="/" className='text-sm font-bold hover:underline'>website</Link>
                    </div>
                </div>
                */}
            </div>

            <div className="my-16 mx-4">
                <SupplyList list={supplies} />
            </div>

        </div>
    )
}

export default TutorialDetailMobile;