import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';

import SS_API from './utils/SS_API';
import queryString from 'query-string';

import { MenuContext } from './context/MenuContext';
import { RecentContext } from './context/RecentContext';

// static routes
import CategoryRoutes from './utils/QTRoutes';

const params = {per_page: 48}

const App = () => {

  const [menu, setMenu] = useState([]);
  const [recentTutorials, setRecentTutorials] = useState([]);

  useEffect(() => {
    const dataTimestamp = localStorage.getItem('qt_tutorials_list') !== null ? JSON.parse(localStorage.getItem('qt_tutorials_list')).timestamp : null;
    
    const timeCheck = new Date();
    let fluffCheck = timeCheck.setMinutes(timeCheck.getMinutes() - 30);

    if(dataTimestamp === null || fluffCheck > dataTimestamp){
      SS_API.get(`/search.json?${queryString.stringify(params)}`)
        .then(res => {
          let qt_tutorial_data = { timestamp: new Date().getTime(), qt_tutorials: res.data.results };
          localStorage.setItem('qt_tutorials_list', JSON.stringify(qt_tutorial_data));
          localStorage.setItem('menu_facets', JSON.stringify(res.data.facets));
          setRecentTutorials(res.data.results.slice(0,5));
          setMenu(res.data.facets);
      });
    }else{
      setMenu(JSON.parse(localStorage.getItem('menu_facets')));
      setRecentTutorials(JSON.parse(localStorage.getItem('qt_tutorials_list')).qt_tutorials.slice(0,5));
    }
    
  }, []);

  return(  
    <MenuContext.Provider value={menu}>
      <RecentContext.Provider value={recentTutorials}>
        <Routes>
          {
            CategoryRoutes.map((el,k) => <Route key={k} path={el.path} element={el.element} /> )
          }
        </Routes>
      </RecentContext.Provider>
    </MenuContext.Provider>
  )

}
export default App;
