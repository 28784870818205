import React, { useState } from 'react';

export const useFontSize = () => {
    const [fontSize, setFontSize] = useState({size: 4, string: 'text-lg'});

    const sizeSelection = {
        1: 'text-xs',
        2: 'text-sm',
        3: 'text-base',
        4: 'text-lg',
        5: 'text-xl',
        6: 'text-2xl',
        7: 'text-3xl',
        8: 'text-4xl',
        9: 'text-5xl',
        10: 'text-6xl',
        11: 'text-7xl',
        12: 'text-8xl',
        13: 'text-9xl'
    }

    const smallerFont = (s) => {
        if(s >= 1) setFontSize({size: s, string: sizeSelection[s]});
    }

    const largerFont = (s) => {
        if(s <= 13) setFontSize({size: s, string: sizeSelection[s]});
    }

    const FontSizeUI = (
        <div>
            <div className='inline-flex rounded-md bg-gray-200 border border-gray-300 divide-x divide-gray-300' role='group'>
                <button onClick={() => smallerFont(fontSize.size - 1)} className='py-.5 px-2 text-sm rounded-l-md hover:bg-gray-100 active:bg-gray-50'>A</button>
                <button onClick={() => largerFont(fontSize.size + 1)} className='py-.5 px-2 text-lg rounded-r-md hover:bg-gray-100 active:bg-gray-50'>A</button>
            </div>
        </div>
    )

    return {
        FontSizeUI,
        fontSize
    }
}