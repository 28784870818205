import React, { useEffect, useState } from 'react';
import axios from 'axios';
import API from '../utils/API';
// import SS_API from '../utils/SS_API';
import { Helmet } from 'react-helmet';
import { shuffle, sortBy } from 'lodash';

import Header from '../components/layout/Header';
import Hero from '../components/widgets/Hero';
import TutorialsList from '../components/layout/TutorialsList';
import TutorialsRob from '../components/layout/TutorialsRob';
import Instructors from '../components/layout/Instructors';
import TypeBlocks from '../components/layout/TypeBlocks';
import AboutQT from '../components/layout/AboutQT';
import Footer from '../components/layout/Footer';
import FooterLinks from '../components/layout/FooterLinks';
import Loader from '../components/widgets/Loader';
import ThreeColumn from '../components/layout/ThreeColumn';

const Home = () => {
    const [tutorialList, setTutorialList] = useState([]);
    const [instructorList, setInstructorList] = useState([]);
    const [isFetching, setIsFetching] = useState(true);

    // const params = {resultsPerPage: 48}
    const params = {per_page: 48}

    const metaInfo = {
        title: 'Free Quilt Tutorials & Quilting Videos | Quilting Tutorials',
        meta: [
            {
                name: 'description',
                content: 'Browse through hundreds of free, high-quality quilting tutorials to find the perfect project for you! Sort quilt videos by skill level, designer, and more!'
            }
        ]
    }

    const featuredTutorials = [
        {
            title: 'Newest Tutorials',
            query: {},
            initialResults: [],
            shuffle: false
        },
        {
            title: 'Tutorials with Jenny',
            query: {Instructors: ['Jenny Doan']},
            initialResults: [],
            shuffle: true
        },
        {
            title: 'Tutorials with Rob',
            query: {Instructors: ['Rob Appell']},
            initialResults: [],
            shuffle: true
        }
        ,
        {
            title: 'Triple Play Quilt Tutorials',
            query: {q: ['Triple Play']},
            initialResults: [],
            shuffle: true
        }
    ]

    const [tutorialsJenny, setTutorialsJenny] = useState(featuredTutorials[1].initialResults);
    const [tutorialsRob, setTutorialsRob] = useState(featuredTutorials[2].initialResults);
    const [tutorialsTriplePlay, setTutorialsTriplePlay] = useState(featuredTutorials[3].initialResults)

    useEffect(() => {            
        // const dataTimestamp = localStorage.getItem('qt_tutorials_list') !== null ? JSON.parse(localStorage.getItem('qt_tutorials_list')).timestamp : null;
    
        // const timeCheck = new Date();
        // let fluffCheck = timeCheck.setMinutes(timeCheck.getMinutes() - 30);

        // if(dataTimestamp === null || fluffCheck > dataTimestamp){

            const requests = featuredTutorials.reduce((result, x) => {
                result.push(API.get('/tutorials/search', {params: Object.assign({}, params, x.query)}))
                return result
            }, [])
            
            axios.all(requests)
            .then(axios.spread(function (...args) {
                args.map(({data}, k) => {
                    featuredTutorials[k].initialResults = data.data
                    if(k === 0) {
                        let qt_tutorial_data = { timestamp: new Date().getTime(), qt_tutorials: data.data };
                        let instructor_list = sortBy(data.meta.facets.find(i => i.title === 'Instructors').categories, x => -x.counter)
                        console.log(instructor_list)
                        //localStorage.setItem('menu_facets', JSON.stringify(data.meta.facets));
                        localStorage.setItem('qt_tutorials_list', JSON.stringify(qt_tutorial_data));
                        localStorage.setItem('qt_instructors', JSON.stringify(instructor_list));

                        setTutorialList(data.data);
                        setInstructorList(instructor_list);
                        
                    }else if(k === 1){
                        localStorage.setItem('jenny_tutorials_list', JSON.stringify(data.data));
                        setTutorialsJenny(data.data);
                    }else if(k === 2){
                        localStorage.setItem('rob_tutorials_list', JSON.stringify(data.data));
                        setTutorialsRob(data.data);
                    }else if(k === 3){
                        localStorage.setItem('triple_play_list', JSON.stringify(data.data));
                        setTutorialsTriplePlay(data.data);
                    }
                });
            }));
        // }else{
        //     setTutorialList(JSON.parse(localStorage.getItem('qt_tutorials_list')).qt_tutorials);
        //     setInstructorList(JSON.parse(localStorage.getItem('qt_instructors')));
        //     setTutorialsJenny(JSON.parse(localStorage.getItem('jenny_tutorials_list')));
        //     setTutorialsRob(JSON.parse(localStorage.getItem('rob_tutorials_list')));
        // }
        setIsFetching(false);
    }, []);

    // simple placeholder
    const heroMessage = `Quilting Tutorials brings the best YouTube quilting tutorials to one place! Browse hundreds of free quilt tutorial videos to find the perfect quilting project. Sort through quilt videos by project type, skill level and more to find the perfect quilt tutorial for you!`;

    return(
        <>
            <Helmet>
                <title>{ metaInfo.title }</title>
                {
                    metaInfo.meta.map((el, i) => <meta name={el.name} content={el.content} key={i} />)
                }
            </Helmet>
            
            <Header />
            { 
                !isFetching && (
                    <Hero message={heroMessage} heroImage='/img/bg-hero.jpg' />
                )
            }            
            
            {
                isFetching && (
                    <Loader />
                )
            }

            {
                !isFetching && tutorialList && tutorialList.length > 0 && (
                    <>
                        <TutorialsList title="Newest tutorials" link="/tutorials" tutorials={shuffle(tutorialList).slice(0,5)} feature={0} source="123" />
                    </>
                )
            }
            {
                !isFetching && tutorialsJenny && tutorialsJenny.length > 0 && (
                    <>
                        <TutorialsList title="Tutorials with Jenny" link={{pathname: '/tutorials', search: 'Instructors=Jenny Doan'}} tutorials={shuffle(tutorialsJenny).slice(0,5)} feature={4} source="123" />
                    </>
                )
            }
            {
                !isFetching && tutorialsRob && tutorialsRob.length > 0 && (
                    // Triple Play Coming in
                    // <TutorialsRob tutorials={tutorialsRob} />
                    <TutorialsRob tutorials={tutorialsTriplePlay} />
                )
            }
            {
                !isFetching && instructorList && instructorList.length > 0 && (
                    <Instructors instructors={instructorList} />
                )    
            }

            {/* <ThreeColumn /> */}
            <TypeBlocks />
            <AboutQT />
            <FooterLinks />
            <Footer />
        </>
    )
}

export default Home;