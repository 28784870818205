const replaceOriginalResource = (resourceUrl, version = 'originals') => {
  return resourceUrl.replace('/originals/', `/${version}/`)
}

const getOriginal = (resourceUrl) => {
  return replaceOriginalResource(resourceUrl, 'originals')
}

const getExtraLarge = (resourceUrl) => {
  return replaceOriginalResource(resourceUrl, '1280x720')
}

const getLarge = (resourceUrl) => {
  return replaceOriginalResource(resourceUrl, '640x480')
}

const getMedium = (resourceUrl) => {
  return replaceOriginalResource(resourceUrl, '480x360')
}

const getSmall = (resourceUrl) => {
  return replaceOriginalResource(resourceUrl, '320x180')
}

const getExtraSmall = (resourceUrl) => {
  return replaceOriginalResource(resourceUrl, '120x90')
}

export {getOriginal, getExtraLarge, getLarge, getMedium, getSmall, getExtraSmall}
