import axios from 'axios';

export default axios.create({
    baseURL: process.env.REACT_APP_API_BASE_SS,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      //'X-CLIENT-ID': process.env.REACT_APP_CLIENT_ID
    },
    params: {
        siteId: process.env.REACT_APP_SITE_ID,
        resultsFormat: 'native',
        resultsPerPage: 48
    }
});