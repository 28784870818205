import React from "react";

import TutorialsListHeader from "../widgets/TutorialsListHeader";
import VideoFeature from "../widgets/VideoFeature";
import SSVideoFeature from "../widgets/SSVideoFeature";

const TutorialsList = (props) => {
    const { title, tutorials, feature, link, recent = false, source = 'SS' } = props;
    
    return(
        <section>
            <TutorialsListHeader title={title} link={link} />

            <div className="container px-4 md:px-0 mx-auto mt-4 lg:max-w-4xl">
                <div className="grid grid-cols-2 gap-4 md:grid-cols-6 md:gap-8">
                    {
                        tutorials && tutorials.map((el, i) => (
                            source !== 'SS'
                            ? <VideoFeature key={el.id} data={el} feature_tut={ i === feature ? true : false } recent={recent} />
                            : <SSVideoFeature key={el.id} data={el} feature_tut={ i === feature ? true : false } recent={recent} />
                        ))
                    }
                </div>
            </div>
        </section>
    )
}

export default TutorialsList;