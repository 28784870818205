import React from "react";
import { Link } from "react-router-dom";

const InstructorSlide = props =>{
    //const { title, description, images } = props.info;
    //const { label, value, description } = props.info;

    const instructorImg = (ins) => {
        if(ins.info.images){
            return ins.info.images.map(i => i.url);
        }
    }

    const instructorLabel = (ins) => {
        if(ins.info.label) {
            return ins.info.label;
        }else{
            return ins.info.title
        }
    }

    return (
        <Link to={{ pathname:'/tutorials', search: `filter.instructors=${instructorLabel(props)}`}} className="flex flex-col items-center justify-center group">
            <div className="border-4 border-bittersweet rounded-full w-32 h-32 flex justify-center items-center">
                <img src={ instructorImg(props) } alt="" className="w-24 h-24 rounded-full" />
            </div>
            <div className="text-center text-greenPawPaw font-extrabold my-4 group-hover:underline cursor-pointer">{ instructorLabel(props) }</div>
            {/* {
                props.info.description && (
                    <div className="text-center text-xs px-8">{ props.info.description }</div>
                )
            } */}
        </Link>
    )
}

export default InstructorSlide;