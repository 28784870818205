import React from "react";
import { Link } from 'react-router-dom';

const TutorialsRob = (props) => {
    const { tutorials } = props;

    const tutorialURL = (tutorial) => {
        if(tutorial.url){
            return tutorial.url.split('.com')[1];
        }else{
            return `/tutorial/${tutorial.slug}`;
        }
    }

    const tutorialIMG = (tutorial) => {
        if(tutorial.imageUrl){
            return tutorial.imageUrl;
        }else{
            return tutorial.youtube_maxres;
        }
    }

    return (
        <section id="tutorials_rob">
            <div className="container mx-auto mt-12 lg:max-w-5xl">
                {/* <div className="mx-auto flex justify-center md:justify-between items-baseline lg:max-w-4xl"> */}
                <div className="lg:max-w-4xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-8">
                    <div className="relative mx-auto md:mx-0">
                        <img src="/img/hero-banner-bg.svg" alt="" className="h-24 w-auto" />
                        <div className="flex flex-col justify-center items-center -mt-12">
                            <div className="text-white text-lg font-bold -mt-2">
                                <Link to={{ pathname: '/tutorials', search: 'q=Triple Play' }} className="font-lg font-bold hover:underline">Triple Play Quilt Tutorials &gt;</Link>
                            </div>
                        </div>
                    </div>
                    <img src="/img/spool-w-needles.svg" alt="" className="hidden md:block md:col-span-2 w-auto h-16 ml-10" />
                </div>
            </div>

            <div className="container mx-auto lg:max-w-5xl bg-lightCyan pt-8 pb-4 px-4 md:px-16">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8 my-8">
                    {
                        tutorials && tutorials.length > 0 && tutorials.slice(0,6).map((el, i) => (
                            <Link to={`${tutorialURL(el)}`} className='relative'  key={i}>
                                <img src={tutorialIMG(el)} alt="" />
                                <div className="md:hidden text-center text-lg font-bold font-greenPewPew px-4">{ el.name }</div>
                            </Link>
                        ))
                    }
                </div>
            </div>
        </section>
    )
}

export default TutorialsRob;