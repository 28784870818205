import React from 'react';
import { Link } from 'react-router-dom';

import linksConfig from '../../utils/linksConfig';
// import Menu from '../layout/Menu';
import Menu from '../layout/SSMenu';
import SearchInput from '../widgets/SeachInput';

const Header = (props) => {

    const orderMe = k => {
        switch(k){
            case 0:
                return `order-2 sm:order-1`
            case 1:
                return `order-3 sm:order-2`
            case 2:
                return `order-1 sm:order-3`
            default:
                // console.log('123')
        }
    }

    return (
        <>
            <section className="mx-auto md:border-b md:border-gray-100 md:pb-4 lg:max-w-5xl">
                <header className="container mx-auto lg:max-w-4xl mt-4 md:pt-8">
                    <div className="flex flex-col md:flex-row md:justify-between md:items-end">
                        <Link to="/" className='order-2 md:order-1 px-4'><img src="/img/logo.svg" alt="" className="w-full md:w-64 h-auto" /></Link>

                        <div className='sm:hidden flex flex-row justify-evenly items-center text-sm font-bold mb-8 gap-2'>
                            <a href='https://www.missouriquiltco.com/collections/on-sale?utm_campaign=qthome&utm_medium=microsite&utm_source=quiltingtut' className='text-vividYellow pl-2 pr-5 py-2 border-2 border-persianGreen border-l-0 rounded-r-full whitespace-nowrap flex justify-center items-center'><img src='/img/header/sale.svg' alt="" className="w-6 h-6 mx-1" /> Sales</a>
                            <a href='https://www.missouriquiltco.com?utm_campaign=qthome&utm_medium=microsite&utm_source=quiltingtut' className='text-softOrange px-6 py-2 border-2 border-persianGreen rounded-full whitespace-nowrap flex flex-shring-0 justify-center items-center'><img src='/img/header/msqc.svg' alt="" className="w-6 h-6 mx-1" /> Quilt Shop</a>
                            <a href='https://www.missouriquiltco.com/pages/daily-deal?utm_campaign=qthome&utm_medium=microsite&utm_source=quiltingtut' className='text-persianGreen pr-2 pl-4 py-2 border-2 border-persianGreen border-r-0 rounded-l-full whitespace-nowrap flex justify-center items-center'><img src='/img/header/daily.svg' alt="" className="w-6 h-6 mx-1" /> Daily Deal</a>
                        </div>

                        <div className="hidden order-1 md:order-2 mb-4 sm:flex flex-row justify-center md:justify-end items-center overflow-x-auto">
                            
                            <div className="flex flex-nowrap flex-shrink-0 gap-2 lg:gap-4 montserrat mx-4">
                                {
                                    linksConfig.quickLinks.map((el,k) => (
                                        <a href={ el.location } key={k} target="_blank" rel="noreferrer" className={`flex-start whitespace-nowrap flex text-sm items-center border border-persianGreen rounded-full px-2 py-1 lg:px-4 lg:py-2 font-bold text-${el.textColor} shadow-md hover:shadow-none ${orderMe(k)}`}>
                                            <img src={ el.icon } alt="" className="w-6 h-6 mx-1" /> { el.title }
                                        </a>
                                    ))
                                }
                            </div>

                            <div className="flex flex-row md:flex-col flex-shrink-0 justify-between">
                                {
                                    linksConfig.social.map((el, k) => (
                                        el.location && (
                                            <a href={ el.location } target="_blank" key={k} rel="noreferrer">
                                                <img src={ el.logo } alt="" className="w-8 h-8 md:w-5 md:h-5 mx-1" />
                                            </a>
                                        )
                                    ))
                                }
                            </div>
                        </div>
                    </div>

                    <div className='md:hidden flex flex-col gap-4 mt-8 px-4'>
                        <SearchInput value="" />
                        <Link to="/tutorials" className="bg-bittersweet text-white text-center rounded text-lg font-bold px-4 py-1 w-full h-8">Browse all tutorials</Link>
                    </div>
                </header>
            </section>

            <Menu />
        </>
    )
}

export default Header;