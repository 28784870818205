import React from 'react';

import BgTutorial from '../../assets/img/tutorial_products.png';
import TutorialProductItem from '../widgets/TutorialProductItem';

const TutorialProducts = props => {
    const { products } = props;

    return (
        <section>
            <div className="container montserrat mx-auto mt-12 bg-lightCyan relative lg:max-w-5xl p-8">
                <div className="my-8 mx-auto lg:max-w-4xl">
                    <div className="grid grid-cols-2 md:grid-cols-6 gap-4">
                        <div className='md:col-span-3 relative bg-white'>
                            <div className="text-2xl md:text-4xl absolute bottom-0 left-0 z-10 mb-4 ml-4 md:ml-6 font-bold text-bittersweet md:text-greenPawPaw">Products<br />from<br className='md:hidden' /> this<br className='md:hidden' /> Tutorial</div>
                            <img src={BgTutorial} alt="" className='h-64 md:h-auto w-full object-cover' />
                        </div>
                        {
                            products && products.map((el, i) => (
                                <TutorialProductItem item={el} key={i} />
                            ))
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TutorialProducts;