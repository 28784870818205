import React from 'react';
import { Link } from 'react-router-dom';
import MissingImg4 from '../../assets/img/launcher-icon-4x.png';
import parse from 'html-react-parser';

import { getMedium, getSmall } from '../../utils/ImageResource';

const VideoFeature = props => {
    const { feature_tut, display_button = true, recent = false, justify = 'between' } = props;
    const { name, url } = props.data;

    const showMeTheButton = () => {
        if (recent) {
            return 'hidden md:inline-block bg-persianGreen hover:bg-bittersweet hover:text-white text-center w-full rounded-lg text-xs font-bold py-2';
        }else{
            return 'hidden md:inline-block bg-lightCyan hover:bg-greenPawPaw hover:text-white text-center w-full text-xs font-bold py-2';
        }
    }

    const imageThumb = (data) => {
        if(typeof data.thumbnail_image === 'string' && data.thumbnail_image.length > 0){
            return data.thumbnail_image;
        }else if(typeof data.hero_image === 'string' && data.hero_image.length > 0) {
            return data.hero_image;
        }else if(typeof data.youtube_maxres === 'string' && data.youtube_maxres.length > 0) {
            return data.youtube_maxres;
        }else if(typeof data.hero_image === 'undefined' && typeof data.images === 'object') {
            const hero_image = data.images.find(i => i.type = 'hero');
            if(hero_image.url){
                return hero_image.url;
            }
        }
    }

    return(
        <>
            {
                feature_tut ? (
                    <Link to={`${ url.split('.com')[1] }`} className="col-span-2 group">
                        <img src={ getMedium(imageThumb(props.data)) } alt={ name } className="w-auto md:h-44" />
                        <p className="text-sm mt-4 group-hover:underline group-hover:cursor-pointer">{ parse(name) }</p>
                    </Link>
                ) : (
                    <div className={`flex flex-col justify-${justify} group`}>
                        <Link to={`${ url.split('.com')[1] }`} className="">
                            <img src={ getSmall(imageThumb(props.data)) } alt={ name } className="w-full h-auto object-cover object-center" onError={ (e) => e.target.src = MissingImg4 } />
                            <p className="my-4 text-sm text-clip text-left break-words tracking-tight group-hover:underline">{ parse(name) }</p>
                        </Link>
                        {
                            display_button && (
                                <Link to={`${ url.split('.com')[1] }`} className={showMeTheButton()}>Watch Now</Link>
                            )
                        }
                    </div>
                )
            }
        </>
    )
}

export default VideoFeature;